<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <ValidationObserver ref="addUserForm" :slim="true">
          <md-card-content>
            <div class="md-layout md-gutter">
              <ValidationProvider
                v-slot="{ errors, reset }"
                class="md-layout-item md-size-100"
                mode="passive"
                tag="div"
                rules="required"
              >
                <md-field :class="{ error: errors.length }">
                  <ValidationError :errors="errors" />
                  <label>Наименование роли</label>
                  <md-input v-model="role.title" @focus="reset" />
                </md-field>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                class="md-layout-item md-size-100"
                mode="passive"
                tag="div"
              >
                <ValidationError :errors="errors" />
                <RoleSwitchTable />
              </ValidationProvider>
            </div>
            <div class="buttons-wrap justify-end">
              <md-button
                class="md-raised md-danger"
                @click="$router.push('/admins/list-role')"
              >
                Отменить
              </md-button>
              <md-button class="md-raised md-success"> Создать </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import RoleSwitchTable from "../../components/Tables/RoleSwitch.vue";

export default {
  components: { RoleSwitchTable },
  data() {
    return {
      role: {
        title: "администратор пользователей",
      },
    };
  },
};
</script>

<style>
.buttons-wrap {
  display: flex;
  align-items: center;
}

.spin {
  margin-left: 20px;
}

.cancel-btn {
  margin-left: 10px !important;
}
.m-0 {
  margin: 0 !important;
}
</style>
