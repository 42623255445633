<template>
  <div>
    <div v-if="CRUD" class="position-table">
      <md-table v-model="CRUD">
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="Название">
            <span class="text">{{ item.label }}</span>
          </md-table-cell>
          <md-table-cell class="w-150px" md-label="Чтение">
            <md-switch v-model="item.isRead" class="md-primary"></md-switch>
          </md-table-cell>
          <md-table-cell class="w-150px" md-label="Редактирование">
            <md-switch v-model="item.isRedact" class="md-primary"></md-switch>
          </md-table-cell>
          <md-table-cell class="w-150px" md-label="Удаление">
            <md-switch v-model="item.isDelete" class="md-primary"></md-switch>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      CRUD: [
        {
          label: "CRUD ролей",
          isRead: false,
          isRedact: false,
          isDelete: false,
        },
        {
          label: "Добавление партнёрских программ",
          isRead: false,
          isRedact: false,
          isDelete: false,
        },
        {
          label: "Восстановление/блокировка аккаунта администратора",
          isRead: false,
          isRedact: false,
          isDelete: false,
        },
        {
          label: "Баланс пользователей",
          isRead: false,
          isRedact: false,
          isDelete: false,
        },
        {
          label: "Подробная информация об аккаунте",
          isRead: false,
          isRedact: false,
          isDelete: false,
        },
        {
          label: "Управление жалобами/обращениями",
          isRead: true,
          isRedact: true,
          isDelete: true,
        },
        {
          label: "Управление запрещенными словами",
          isRead: true,
          isRedact: true,
          isDelete: true,
        },
        {
          label: "Управление быстрыми фразами/эмоциями",
          isRead: true,
          isRedact: true,
          isDelete: true,
        },
        {
          label: "Управление заявками на выплату",
          isRead: false,
          isRedact: false,
          isDelete: false,
        },
        {
          label: "Управление кастомизацией",
          isRead: true,
          isRedact: true,
          isDelete: true,
        },
        {
          label: "Никнейм",
          isRead: true,
          isRedact: true,
          isDelete: false,
        },
        {
          label: "Почта Пользователя",
          isRead: true,
          isRedact: true,
          isDelete: false,
        },
        {
          label: "UserId пользователей",
          isRead: true,
          isRedact: false,
          isDelete: false,
        },
        {
          label: "Дата регистрации",
          isRead: true,
          isRedact: false,
          isDelete: false,
        },
        {
          label: "Дата последней активности",
          isRead: true,
          isRedact: false,
          isDelete: false,
        },
        {
          label: "Бан аккаунта",
          isRead: true,
          isRedact: true,
          isDelete: true,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.text {
  word-break: break-word;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pages {
  margin-right: 20px;
  margin-left: 20px;
}
.w-150px {
  width: 150px;
}
</style>
